const sx = {
    '.MuiDataGrid-columnSeparator': {
        display: 'none'
    },
    '&.MuiDataGrid-root': {
        borderRight: 'none',
        borderLeft: 'none',
        borderTop: 'none'
    },
    '& .MuiDataGrid-columnHeaders': {
        backgroundColor: '#F5F5F5'
    },
    '& .MuiDataGrid-row': {
        cursor: 'pointer'
    }
};

export const GridStyles = {
    sx: sx
};
