import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetRationaleByIdQuery } from '@app/services/rationalesApi';
import Loader from '@app/components/utility/loader';
import { Content } from 'antd/lib/layout/layout';
import { ALL_PAGE_SIZES } from '@app/utilities/helpers/Constants';
import { DataGrid } from '@mui/x-data-grid';
import { columns, toGridRows } from '@app/pages/NudgeListPage/NudgeListColumns';
import { GridStyles } from '@app/assets/styles/GridStyles';
import { CustomPagination } from '@app/components/shared/CustomPagination/CustomPagination';
import { CustomFooter } from '@app/components/shared/CustomFooter/CustomFooter';
import {
    NoResultsOverlay,
    NoRowsOverlay
} from '@app/shared/components/NoRowsOverlay';
import { ApiPermissionsEnum } from '@app/enums/GeneralEnums';
import { usePermissions } from '@app/shared/hooks/usePermissions';
import { DetailsHeader } from '@app/components/DetailsHeader/DetailsHeader';

const RationalDetailsPage = () => {
    const { rationaleId } = useParams();
    const { hasPermission } = usePermissions();

    const navigate = useNavigate();

    const {
        data: rational,
        isUninitialized,
        isLoading
    } = useGetRationaleByIdQuery(Number(rationaleId));

    if ((isLoading || !rational) && !isUninitialized) {
        return <Loader />;
    }

    // @ts-ignore
    const rowCount = rational?.nudge_list.length;

    const goToNudge = (nudgeId: number) => navigate(`/nudge/${nudgeId}`);

    return (
        <div className="humucontent nudge-list">
            <div className="humuLeftContent humuLeftForm">
                <Content>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-8 mt-3" />
                        </div>
                        <h1 className="heading">Rationales</h1>
                        <DetailsHeader type={'Rationales'} data={rational} />
                        <h1 className="heading">Associated Nudges</h1>
                        <div className="row">
                            <div className="col-12 mt-3">
                                <DataGrid
                                    components={{
                                        Pagination: CustomPagination,
                                        Footer: CustomFooter,
                                        NoRowsOverlay: NoRowsOverlay,
                                        NoResultsOverlay: NoResultsOverlay
                                    }}
                                    componentsProps={{
                                        footer: { totalRecords: rowCount }
                                    }}
                                    autoHeight
                                    disableSelectionOnClick
                                    // @ts-ignore
                                    rows={toGridRows(rational.nudge_list)}
                                    columns={columns}
                                    onRowClick={(params) => {
                                        if (
                                            hasPermission(
                                                ApiPermissionsEnum.VIEW_NUDGE
                                            )
                                        ) {
                                            goToNudge(Number(params.id));
                                        }
                                    }}
                                    sx={GridStyles.sx}
                                    rowsPerPageOptions={ALL_PAGE_SIZES}
                                    filterMode="client"
                                    sortingMode="client"
                                />
                            </div>
                        </div>
                    </div>
                </Content>
            </div>
        </div>
    );
};

export default RationalDetailsPage;
