import { lazy, Suspense } from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ErrorBoundary from './ErrorBoundary';
import Loader from './components/utility/loader';
import { RootState } from './store/store';
import Login from './features/auth/Login';
import { ProtectedRoute } from './ProtectedRoute';
import NotFound from './components/NotFound/NotFound';
import ParagraphList from './pages/ParagraphListPage/ParagraphListPage';
import ForgotPassword from '@app/features/auth/ForgotPassword';
import ConfirmPassword from '@app/features/auth/ConfirmPassword';
import { Toaster } from 'react-hot-toast';
import EmailListPage from './pages/EmailListPage/EmailListPage';
import RationalDetailsPage from '@app/pages/RationalDetailsPage/RationalDetailsPage';
import ApplicationDetailsPage from '@app/pages/ApplicationDetailsPage/ApplicationDetailsPage';

const NudgeList = lazy(() => import('./pages/NudgeListPage/NudgeListPage'));
const NudgePage = lazy(() => import('./pages/NudgePage/NudgePage'));
const Rationales = lazy(
    () => import('./pages/RationaleListPage/RationaleListPage')
);
const Applications = lazy(
    () => import('./pages/ApplicationListPage/ApplicationListPage')
);

const AppRoutes = () => {
    const isLoggedIn = useSelector<RootState>((state) => state.auth.access);
    return (
        <ErrorBoundary>
            <Suspense fallback={<Loader />}>
                <Toaster />
                <BrowserRouter>
                    <Routes>
                        <Route path="login" element={<Login />} />
                        <Route
                            path="forgot-password"
                            element={<ForgotPassword />}
                        />
                        <Route
                            path="confirm-password"
                            element={<ConfirmPassword />}
                        />
                        <Route
                            path="/"
                            element={<ProtectedRoute isAllowed={isLoggedIn} />}
                        >
                            <Route index element={<NudgeList />} />
                            <Route path="nudges" element={<NudgeList />} />
                            <Route
                                path="Paragraphs"
                                element={<ParagraphList />}
                            />

                            <Route path="nudge" element={<NudgePage />} />
                            <Route
                                path="nudge/:nudgeId"
                                element={<NudgePage />}
                            />
                            <Route path="rationales" element={<Rationales />} />
                            <Route
                                path="rationale/:rationaleId"
                                element={<RationalDetailsPage />}
                            />
                            <Route
                                path="applications"
                                element={<Applications />}
                            />
                            <Route
                                path="application/:applicationId"
                                element={<ApplicationDetailsPage />}
                            />
                            <Route path="/email" element={<EmailListPage />} />
                        </Route>

                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </BrowserRouter>
            </Suspense>
        </ErrorBoundary>
    );
};

export default AppRoutes;
