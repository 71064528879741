import { Toaster } from 'react-hot-toast';
import { Navigate, Outlet } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import TopBar from './components/TopBar/TopBar';
import { DashboardContainer, DashboardGlobalStyles } from './isomorphic/components/Dashboard';

export const ProtectedRoute = ({
    isAllowed, redirectPath = '/login',
}) => {

    if (!isAllowed) {
        return <Navigate to={redirectPath} replace />;
    }

    return (
        <DashboardContainer>
            <Toaster />
            <DashboardGlobalStyles />
            <Layout>
                <TopBar />
                <Layout>
                    <Outlet />
                </Layout>
            </Layout>
        </DashboardContainer>
    );
};
