import { ContentStatusIcon } from '@app/shared/components/ContentStatusIcon';
import {
    getGridStringOperators,
    GridColumns,
    getGridSingleSelectOperators
} from '@mui/x-data-grid';
import { TooltipView } from '@app/shared/components/TooltipView';
import { ArrayServices } from '@app/utilities/helpers/ArrayServices';
import { NudgeData } from '@app/entities/NudgeData';
import { INudgeModel } from '@app/entities/models/Nudge/INudgeModel';
import moment from 'moment';
import { UserHelper } from '@app/utilities/helpers/UserHelper';
import { OVER_ALL_RESTRICTIONS } from '@app/utilities/helpers/Constants';

const operatorsTypeText = ['equals', 'startsWith', 'contains'];
export const columns: GridColumns<any> = [
    {
        field: 'id',
        headerName: 'Id',
        flex: 1,
        hide: true
    },
    {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        filterOperators: getGridStringOperators().filter(({ value }) =>
            operatorsTypeText.includes(value)
        ),
        hideable: false
    },
    {
        field: 'content_status',
        headerName: 'Content Status',
        flex: 1,
        renderCell: (params) => {
            return ContentStatusIcon(params.value);
        },
        type: 'singleSelect',
        valueOptions: ['draft', 'pushed'],
        filterOperators: getGridSingleSelectOperators().filter(({ value }) =>
            ['is'].includes(value)
        ),
        hideable: false
    },
    {
        field: 'tag_status',
        headerName: 'Tag Status',
        flex: 1,
        type: 'singleSelect',
        valueOptions: ['draft', 'pushed'],
        renderCell: (params) => {
            return ContentStatusIcon(params.value);
        },
        filterOperators: getGridSingleSelectOperators().filter(({ value }) =>
            ['is'].includes(value)
        ),
        hideable: false
    },
    {
        field: 'primary_behaviors',
        headerName: 'Primary Behavior',
        flex: 2,
        filterOperators: getGridStringOperators().filter(({ value }) =>
            ['equals'].includes(value)
        ),
        renderCell: (params) => {
            return TooltipView(params.value);
        },
        hideable: false
    },
    {
        field: 'overall_restrictions',
        headerName: 'Overall Restrictions',
        flex: 1,
        type: 'singleSelect',
        valueOptions: ['True', 'False'],
        filterOperators: getGridSingleSelectOperators().filter(({ value }) =>
            ['is'].includes(value)
        )
    },
    {
        field: 'updated_at',
        headerName: 'Last Updated',
        flex: 1,
        filterable: false,
        hideable: false
    },
    {
        field: 'last_updated_by',
        headerName: 'Last Updated By',
        flex: 1,
        filterable: false,
        hideable: false
    },
    {
        field: 'created_by',
        headerName: 'Created By',
        flex: 1,
        filterable: false,
        hideable: false
    },
    {
        field: 'actions',
        type: 'actions',
        width: 80,
        getActions: () => []
    }
];

export const overallRestrictions = (value: boolean): string => {
    switch (value) {
        case true: {
            return OVER_ALL_RESTRICTIONS.ORGANIZATION;
        }

        case false: {
            return OVER_ALL_RESTRICTIONS.DISABLE;
        }

        default: {
            return OVER_ALL_RESTRICTIONS.ENABLE;
        }
    }
};

export function toGridRows(nudges: INudgeModel[]) {
    return nudges.map((x) => ({
        id: x.id,
        name: x.name,
        content_status: x.content_status,
        tag_status: x.tag_status,
        primary_behaviors: primaryBehavior(x.primary_behaviors),
        overall_restrictions: overallRestrictions(x.overall_restrictions),
        updated_at: moment(x.updated_at).format('MM/DD/YYYY h:mm:ss'),
        last_updated_by: UserHelper.userFullName(x.modified_by),
        created_by: UserHelper.userFullName(x.created_by)
    }));
}

export function primaryBehavior(primaryBehaviors: string[]) {
    return ArrayServices.convertIdToIdNamePairList(
        NudgeData.BehaviorMapping,
        primaryBehaviors
    )
        .map((x) => x.name)
        .join(', ');
}
