import moment from 'moment/moment';

export class StringHelper {
    public static stripTags(text?: string) {
        return text?.replace(/<[^>]+>/g, '');
    }
    public static formatDate(date: string) {
        return moment(date).format('MM/DD/YYYY h:mm:ss');
    }
}
