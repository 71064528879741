import { GridStyles } from '@app/assets/styles/GridStyles';
import { SearchField } from '@app/controls/SearchField';
import { useGetParagraphsQuery } from '@app/services/rationalesApi';
import { useDebounce } from '@app/shared/hooks/useDebounce';
import {
    DataGrid,
    GridFilterItem,
    GridFilterModel,
    GridSortModel
} from '@mui/x-data-grid';
import { UserHelper } from '@app/utilities/helpers/UserHelper';
import { Content } from 'antd/lib/layout/layout';
import 'bootstrap/dist/css/bootstrap.css';
import moment from 'moment';
import { useEffect, useState, useCallback } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { columns } from './paragraphListColumns';
import { CustomPagination } from '@app/components/shared/CustomPagination/CustomPagination';
import { IParagraphResponseModel } from '@app/entities/models/ParaGraphModel/IParagraphResponseModel';
import { CustomFooter } from '@app/components/shared/CustomFooter/CustomFooter';
import { NoRowsOverlay } from '@app/shared/components/NoRowsOverlay';

const ParagraphList = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm);

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(15);
    const [paragraphType, setParagraphType] = useState('INTRO');
    const [queryOptionsFilters, setQueryOptionsFilters] = useState<
        GridFilterItem[]
    >([]);
    const [queryOptionsSorting, setQueryOptionsSorting] =
        useState<GridSortModel>([]);

    const { data: paragraphList, isFetching } = useGetParagraphsQuery({
        purpose: paragraphType,
        search: debouncedSearchTerm,
        page: page + 1,
        filters: queryOptionsFilters,
        sorting: queryOptionsSorting
    });

    const rowCount = paragraphList?.count;
    const [rowCountState, setRowCountState] = useState(rowCount);

    useEffect(() => {
        setRowCountState((prevRowCountState) =>
            rowCount !== undefined ? rowCount : prevRowCountState
        );
    }, [rowCount, setRowCountState]);

    const onSearch = (keyword: string) => {
        setPage(0);
        setSearchTerm(keyword);
    };

    const gridRows = toGridRows(paragraphList);

    const onFilterChange = useCallback((filterModel: GridFilterModel) => {
        setPage(0);
        setQueryOptionsFilters(filterModel.items);
    }, []);

    const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
        setQueryOptionsSorting(sortModel);
    }, []);

    const onParagraphSelect = (event: SelectChangeEvent) => {
        setParagraphType(event.target.value as string);
    };

    function toGridRows(paragraphList: IParagraphResponseModel) {
        if (!paragraphList?.results) {
            return [];
        }
        return paragraphList.results.map((x) => ({
            id: x.id,
            name: x.name,
            content_status: x.content_status,
            content_html: x.content_html,
            updated_at: moment(x.updated_at).format('MM/DD/YYYY h:mm:ss'),
            lastUpdatedBy: UserHelper.userFullName(x.modified_by),
            createdBy: UserHelper.userFullName(x.created_by)
        }));
    }

    return (
        <div className="humucontent nudge-list">
            <div className="humuLeftContent humuLeftForm">
                <Content>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-8 mt-3">
                                <h1> {paragraphType + ' Paragraph'}</h1>
                            </div>
                            {/* uncomment for search functionality in future
                            <div className="col-4 mt-3 float-end">
                                <SearchField
                                    title="Search Intro Paragraphs"
                                    onChange={onSearch}
                                />
                            </div> */}
                        </div>
                        <Box sx={{ minWidth: 150 }}>
                            <FormControl style={{ marginTop: 10, width: 200 }}>
                                <InputLabel
                                    id="demo-customized-select-label"
                                    style={{ width: 200 }}
                                >
                                    Selected
                                </InputLabel>
                                <Select
                                    labelId="demo-customized-select-label"
                                    id="demo-simple-select"
                                    value={paragraphType}
                                    label="paragraph"
                                    onChange={onParagraphSelect}
                                >
                                    <MenuItem value={'INTRO'}>
                                        Intro Paragraph
                                    </MenuItem>
                                    <MenuItem value={'OUTRO'}>
                                        Outro Paragraph
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <div className="row">
                            <div className="col-12 mt-3">
                                <DataGrid
                                    autoHeight
                                    disableSelectionOnClick
                                    rows={gridRows}
                                    columns={columns}
                                    sx={GridStyles.sx}
                                    rowCount={rowCountState}
                                    loading={isFetching}
                                    rowsPerPageOptions={[50]}
                                    pagination
                                    page={page}
                                    pageSize={pageSize}
                                    paginationMode="server"
                                    filterMode="server"
                                    onFilterModelChange={onFilterChange}
                                    onPageChange={(newPage) => setPage(newPage)}
                                    onPageSizeChange={(newPageSize) =>
                                        setPageSize(newPageSize)
                                    }
                                    components={{
                                        Pagination: CustomPagination,
                                        Footer: CustomFooter,
                                        NoRowsOverlay: NoRowsOverlay
                                    }}
                                    componentsProps={{
                                        footer: { totalRecords: rowCount }
                                    }}
                                    sortingMode="server"
                                    onSortModelChange={handleSortModelChange}
                                />
                            </div>
                        </div>
                    </div>
                </Content>
            </div>
        </div>
    );
};

export default ParagraphList;
