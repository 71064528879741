import type { LoginInput, LoginResponse } from './types';
import { emptySplitApi } from './emptySplitApi'
import { ConfirmPasswordInput, ResetPasswordInput } from "@app/services/types/LoginInput";
import { ResetPasswordResponse } from "@app/services/types/LoginResponse";

export const authApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation<LoginResponse, LoginInput>({
            query: (credentials) => ({
                url: 'auth/token/',
                method: 'POST',
                body: credentials
            })
        }),
        resetPassword: builder.mutation<
            ResetPasswordResponse,
            ResetPasswordInput
        >({
            query: (credentials) => ({
                url: 'auth/password_reset/',
                method: 'POST',
                body: credentials
            })
        }),
        confirmPassword: builder.mutation<
            ResetPasswordResponse,
            ConfirmPasswordInput
        >({
            query: (credentials) => ({
                url: 'auth/password_reset/confirm/',
                method: 'POST',
                body: credentials
            })
        })
    })
});

export const { useLoginMutation, useResetPasswordMutation, useConfirmPasswordMutation } = authApi;
