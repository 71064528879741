import { ContentStatusIcon } from '@app/shared/components/ContentStatusIcon';
import {
    getGridStringOperators,
    GridColumns,
    getGridSingleSelectOperators
} from '@mui/x-data-grid';
import { TooltipView } from '@app/shared/components/TooltipView';

const operatorsTypeText = ['equals', 'startsWith', 'contains'];

export const columns: GridColumns<any> = [
    {
        field: 'id',
        headerName: 'Id',
        flex: 1, // sort: true,
        hide: true
    },
    {
        field: 'name',
        headerName: 'Name',
        flex: 1, // sort: true,
        filterOperators: getGridStringOperators().filter(({ value }) =>
            operatorsTypeText.includes(value)
        ),
        hideable: false
    },
    {
        field: 'content_status',
        headerName: 'Content Status',
        flex: 0.5,
        renderCell: (params) => {
            return ContentStatusIcon(params.value);
        },
        type: 'singleSelect',
        valueOptions: ['draft', 'pushed'],
        filterOperators: getGridSingleSelectOperators().filter(({ value }) =>
            ['is'].includes(value)
        ),
        hideable: false
    },
    {
        field: 'content_html',
        headerName: 'Content',
        flex: 1,
        renderCell: (params) => {
            return TooltipView(params.value);
        },
        hideable: false
    },

    {
        field: 'updated_at',
        headerName: 'Last Updated',
        flex: 1,
        filterable: false,
        hideable: false
    },
    {
        field: 'lastUpdatedBy',
        headerName: 'Last Updated By',
        flex: 1,
        filterable: false,
        hideable: false
    },
    {
        field: 'createdBy',
        headerName: 'Created By',
        flex: 1,
        filterable: false,
        hideable: false
    },
    {
        field: 'actions',
        type: 'actions',
        width: 80,
        getActions: (params) => []
    }
];
