import { IUserModel } from "@app/entities/models";

export class UserHelper {
    static userFullName(user: IUserModel) {
        if (!user) {
            return 'N/A';
        }

        if (user.first_name && user.last_name) {
            return `${user.first_name} ${user.last_name}`;
        }

        return user.first_name || user.last_name;
    }
}
