import { IdNamePair } from '@app/controls/TypeaheadControl';
import _ from 'lodash';

export class ArrayServices {
    static replaceItemInAnArray(arr: any, key = 'id') {
        return {
            with: function (arr2: any) {
                return _.map(arr, (item: any) => {
                    return (
                        _.find(arr2, (obj: any) => obj[key] === item[key]) ||
                        item
                    );
                });
            }
        };
    }

    /**
     * Takes list of ids and returns IdNamePair list. If id is not found it just uses
     * id as name.
     * @param list The available list
     * @param selectedIds Selected Ids (just string array)
     * @returns
     */
    static convertIdToIdNamePairList = (
        list: IdNamePair[],
        selectedIds: string[]
    ): IdNamePair[] => {
        return selectedIds.map((id) => {
            const index = list.findIndex((item: IdNamePair) => item.id === id);
            return index !== -1 ? list[index] : { id, name: id };
        });
    };
}
