import Dropdown from 'react-bootstrap/Dropdown';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import Avatar from '@app/components/Avatar/Avatar';
import React from 'react';

export interface IMenuItem {
    title: string;
    onPress: () => void;
}

interface IProps {
    items: IMenuItem[];
}

const DropdownMenu = ({ items }: IProps) => {
    return (
        <Dropdown>
            <Nav className="mr-0 ">
                <Nav.Link
                    as={Link}
                    to="#"
                    className="me-3 nav-link-height"
                >
                    <Dropdown.Toggle className="bg-transparent border-light outline-light w-50 no-default">
                        <Avatar />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="ant-popover  ant-popover-placement-bottomRight check">
                        <div className="ant-popover-content">
                            <div className="ant-popover-arrow"></div>
                            <div className="ant-popover-inner" role="tooltip">
                                <div>
                                    <div className="ant-popover-inner-content">
                                        <div
                                            className="sc-gqjmRU cqVVdn isoUserDropdown"
                                            data-rtl="ltr"
                                        >
                                            {items &&
                                                items.map((item) => {
                                                    return (
                                                        <Dropdown.Item
                                                            onClick={
                                                                item.onPress
                                                            }
                                                            className="isoDropdownLink"
                                                            key={item.title}
                                                        >
                                                            <span>
                                                                {item.title}
                                                            </span>
                                                        </Dropdown.Item>
                                                    );
                                                })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dropdown.Menu>
                </Nav.Link>
            </Nav>
        </Dropdown>
    );
};
export default DropdownMenu;
