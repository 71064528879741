import { ApiFilter, ApiSort } from './types/apiParams';
import { DEFAULT_PAGE_SIZE } from '@app/utilities/helpers/Constants';

const filterParams = (filters: ApiFilter[]) => {
    if (!filters || !filters.at(-1)?.hasOwnProperty('value')) {
        return null;
    }

    const createFieldName = (fieldName: string, filterOperator: string) => {
        if (
            filterOperator.toLowerCase() === 'equals' ||
            filterOperator.toLowerCase() === 'is'
        ) {
            return fieldName;
        }

        return `${fieldName}__${filterOperator.toLowerCase()}`;
    };

    return filters
        .map(
            (filter) =>
                `${createFieldName(filter.columnField, filter.operatorValue)}=${
                    filter.value
                }`
        )
        .join('&');
};

const sortingParams = (sorting: ApiSort[]) => {
    if (!sorting || sorting.length === 0) {
        return null;
    }

    const sortOperator = (sortDir: string) => (sortDir === 'asc' ? '' : '-');

    return (
        'ordering=' +
        sorting
            .map(
                (sortModel) =>
                    `${sortOperator(sortModel.sort)}${sortModel.field}`
            )
            .join(',')
    );
};

export const constructGetListParams = ({
    search,
    filters,
    sorting,
    page,
    pageSize
}: any) => {
    return [
        filterParams(filters),
        sortingParams(sorting),
        `search=${search ?? ''}`,
        `page=${page ?? 1}`,
        `page_size=${pageSize ?? DEFAULT_PAGE_SIZE}`
    ]
        .filter((p) => p)
        .join('&');
};

export const constructImageSearchParams = ({
    pageSize,
    nameContains
}: any) => {
    return [
        `page_size=${pageSize ?? DEFAULT_PAGE_SIZE}`,
        `name__contains=${nameContains ?? ''}`
    ]
        .join('&');
};
