import { Link } from 'react-router-dom';

import robImage from '@app/assets/images/rob.png';
import FourZeroFourStyleWrapper from '../../assets/styles/404.styles';

const NotFound = () => {
    return (
        <FourZeroFourStyleWrapper className="iso404Page">
            <div className="iso404Content">
                <h1>404</h1>
                <h3>Looks like you got lost</h3>
                <p>
                    The page you're looking for doesn't exist or has been moved.
                </p>
                <Link to="/">
                    <button type="button" className="btn btn-primary">
                        Back To Home
                    </button>
                </Link>
            </div>

            <div className="iso404Artwork">
                <img alt="#" src={robImage} />
            </div>
        </FourZeroFourStyleWrapper>
    );
};

export default NotFound;
