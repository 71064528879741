import { UserHelper } from '@app/utilities/helpers/UserHelper';
import {
    DataGrid,
    GridFilterItem,
    GridFilterModel,
    GridSortModel
} from '@mui/x-data-grid';
import 'bootstrap/dist/css/bootstrap.css';
import moment from 'moment';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { columns } from './EmailListColumns';
import { GridStyles } from '@app/assets/styles/GridStyles';
import { useCallback, useEffect, useState } from 'react';
import { SearchField } from '@app/controls/SearchField';
import { Content } from 'antd/lib/layout/layout';
import { CustomPagination } from '@app/components/shared/CustomPagination/CustomPagination';
import { useDebounce } from '@app/shared/hooks/useDebounce';
import { useGetEmailsQuery } from '@app/services/rationalesApi';
import { IEmailResponseModel } from '@app/entities/models/EmailModels/IEmailResponseModel';
import { CustomFooter } from '@app/components/shared/CustomFooter/CustomFooter';
import { NoRowsOverlay } from '@app/shared/components/NoRowsOverlay';

const EmailListPage = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm);
    const [queryOptionsFilters, setQueryOptionsFilters] = useState<
        GridFilterItem[]
    >([]);
    const [queryOptionsSorting, setQueryOptionsSorting] =
        useState<GridSortModel>([]);

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(15);
    const { data: emailList, isFetching } = useGetEmailsQuery({
        search: debouncedSearchTerm,
        page: page + 1,
        filters: queryOptionsFilters,
        sorting: queryOptionsSorting
    });

    const rowCount = emailList?.count;
    const [rowCountState, setRowCountState] = useState(rowCount);
    useEffect(() => {
        setRowCountState((prevRowCountState) =>
            rowCount !== undefined ? rowCount : prevRowCountState
        );
    }, [rowCount, setRowCountState]);

    const onSearch = (keyword: string) => {
        setPage(0);
        setSearchTerm(keyword);
    };

    function toGridRows(email: IEmailResponseModel) {
        if (!emailList?.results.map) {
            return [];
        }

        return emailList.results.map((x) => ({
            id: x.id,
            name: x.name,
            email: x.email,
            updated_at: moment(x.updated_at).format('MM/DD/YYYY h:mm:ss'),
            last_updated_by: UserHelper.userFullName(x.modified_by),
            created_by: UserHelper.userFullName(x.created_by)
        }));
    }

    const onFilterChange = useCallback((filterModel: GridFilterModel) => {
        setPage(0);
        setQueryOptionsFilters(filterModel.items);
    }, []);

    const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
        setQueryOptionsSorting(sortModel);
    }, []);

    return (
        <div className="humucontent nudge-list">
            <div className="humuLeftContent humuLeftForm">
                <Content>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-6 mt-3">
                                <h1>Emails</h1>
                            </div>
                            {/* uncomment after search implement from backend
                             <div className="col-4 mt-3 float-end">
                                <SearchField
                                    title="Search Nudges"
                                    onChange={onSearch}
                                />
                            </div> */}
                        </div>
                        <div className="row">
                            <div className="col-12 mt-3">
                                <DataGrid
                                    components={{
                                        Pagination: CustomPagination,
                                        Footer: CustomFooter,
                                        NoRowsOverlay: NoRowsOverlay
                                    }}
                                    componentsProps={{
                                        footer: { totalRecords: rowCount }
                                    }}
                                    autoHeight
                                    disableSelectionOnClick
                                    rows={toGridRows(emailList)}
                                    columns={columns}
                                    sx={GridStyles.sx}
                                    rowCount={rowCountState}
                                    loading={isFetching}
                                    rowsPerPageOptions={[50]}
                                    pagination
                                    page={page}
                                    pageSize={pageSize}
                                    paginationMode="server"
                                    onPageChange={(newPage) => setPage(newPage)}
                                    onPageSizeChange={(newPageSize) =>
                                        setPageSize(newPageSize)
                                    }
                                    filterMode="server"
                                    onFilterModelChange={onFilterChange}
                                    sortingMode="server"
                                    onSortModelChange={handleSortModelChange}
                                />
                            </div>
                        </div>
                    </div>
                </Content>
            </div>
        </div>
    );
};

export default EmailListPage;
