import { IApplicationResponseModel } from '@app/entities/models/Application/ResponseModels/IApplicationResponseModel';
import { IApplicationsResponseModel } from '@app/entities/models/Application/ResponseModels/IApplicationsResponseModel';
import { emptySplitApi } from './emptySplitApi';
import { ApiParams } from './types/apiParams';
import { constructGetListParams } from '@app/services/ApiHelper';

export const applicationsApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getApplications: builder.query<IApplicationsResponseModel, ApiParams>({
            query: (params: ApiParams) => {
                return {
                    url: `api/applications/?${constructGetListParams(params)}`
                };
            },
            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result.results?.map(({ id }) => ({
                              type: 'Applications' as const,
                              id
                          })),
                          'Applications'
                      ]
                    : ['Applications']
        }),
        getApplicationById: builder.query<IApplicationResponseModel, number>({
            query: (id) => ({
                url: `api/applications/${id}/`
            }),
            providesTags: (result, error, id) => [{ type: 'Applications', id }]
        }),
        addApplication: builder.mutation<
            IApplicationResponseModel,
            IApplicationResponseModel
        >({
            query: (application) => ({
                url: 'api/applications/',
                method: 'POST',
                body: application
            }),
            invalidatesTags: ['Applications']
        }),
        updateApplication: builder.mutation<
            IApplicationResponseModel,
            IApplicationResponseModel
        >({
            query: (application) => ({
                url: `api/applications/${application.id}/`,
                method: 'PUT',
                body: application
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Applications', id: result.id }
            ]
        }),
        deleteApplication: builder.mutation<void, string>({
            query: (id) => ({
                url: `api/applications/${id}/`,
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Applications', id: arg }
            ]
        })
    })
});

export const {
    useGetApplicationsQuery,
    useUpdateApplicationMutation,
    useGetApplicationByIdQuery
} =
    applicationsApi;
