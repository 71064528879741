import Pagination from '@mui/material/Pagination';
import './CustomPagination.css';
import {
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector
} from '@mui/x-data-grid';
import { TablePagination } from '@mui/material';
import { useState } from 'react';
import {
    ALL_PAGE_SIZES,
    DEFAULT_PAGE_SIZE
} from '@app/utilities/helpers/Constants';

export function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    const [rowsPerPage, setRowsPerPage] = useState<number>(DEFAULT_PAGE_SIZE);
    const rowsPerPageOptions: (number | { value: number; label: string })[] =
        ALL_PAGE_SIZES;

    return (
        <>
            <TablePagination
                count={Number(pageCount)}
                page={Number(page)}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={rowsPerPageOptions}
                onRowsPerPageChange={(event) => {
                    const rows =
                        Number(event.target.value) ?? DEFAULT_PAGE_SIZE;
                    setRowsPerPage(rows);
                    apiRef.current.setPageSize(rows);
                }}
                onPageChange={() => null}
            />
            <Pagination
                color="primary"
                shape="rounded"
                page={page + 1}
                count={pageCount}
                onChange={(event: React.ChangeEvent<unknown>, value: number) =>
                    apiRef.current.setPage(value - 1)
                }
            />
        </>
    );
}
