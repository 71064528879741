import '@app/App.css';
import GlobalStyles from '@app/assets/styles/globalStyle';
import '@app/assets/styles/HumuGeneralStyles.css';
import '@app/components/Avatar/Avatar.css';
import '@app/components/DropdownMenu/DropdownMenu.css';
import AppRoutes from './router';

function App() {
    return (
        <>
            <GlobalStyles />
            <AppRoutes />
        </>
    );
}

export default App;
