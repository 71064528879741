import { ContentStatusIcon } from '@app/shared/components/ContentStatusIcon';
import { getGridStringOperators, GridColumns } from '@mui/x-data-grid';

const operatorsTypeText = ['equals', 'startsWith', 'contains'];
export const columns: GridColumns<any> = [
    {
        field: 'id',
        headerName: 'Id',
        flex: 1,
        hide: true
    },
    {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        filterOperators: getGridStringOperators().filter(({ value }) =>
            operatorsTypeText.includes(value)
        ),
        hideable: false
    },
    {
        field: 'email',
        headerName: 'Email',
        flex: 1,
        filterOperators: getGridStringOperators().filter(({ value }) =>
            operatorsTypeText.includes(value)
        ),
        hideable: false
    },

    {
        field: 'updated_at',
        headerName: 'Last Updated',
        flex: 1,
        filterable: false,
        hideable: false
    },
    {
        field: 'last_updated_by',
        headerName: 'Last Updated By',
        flex: 1,
        filterable: false,
        hideable: false
    },
    {
        field: 'created_by',
        headerName: 'Created By',
        flex: 1,
        filterable: false,
        hideable: false
    },
    {
        field: 'actions',
        type: 'actions',
        width: 80,
        getActions: () => []
    }
];
