import { RootState, store } from '@app/store/store';
import { JwtHelper } from '@app/utilities/helpers/JwtHelper';

export function usePermissions() {
    const permissions = getUserPermissions();

    const hasPermission = (permission: string): boolean =>
        permissions.some((p: string) => p === permission);

    return { hasPermission };
}

function getUserPermissions() {
    const token = (store.getState() as RootState).auth.access;
    const decodedJwt = JwtHelper.parseJwt(token);
    return decodedJwt.permissions || [];
}
