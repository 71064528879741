export const insightIds = [
    'allyship',
    'allyship_1',
    'authenticity',
    'authenticity_2',
    'autonomy_1',
    'autonomy_2',
    'autonomy_3',
    'autonomy_4',
    'autonomy_6',
    'autonomy_cf_5',
    'auttonomy_1',
    'belonging_1',
    'belonging_2',
    'belonging_3',
    'belonging_4',
    'belonging_5',
    'belongining_2',
    'belongning_1',
    'beloning_1',
    'beloning_2',
    'beloning_3',
    'beloning_4',
    'broccoli',
    'broccoli_ac',
    'broccoli_di',
    'broccoli_me',
    'clarification',
    'collaboration_1',
    'collaboration_2',
    'communication_1',
    'communication_2',
    'consideration',
    'consideration_1',
    'consideration_2',
    'consideration_3',
    'consideration_4',
    'consideration_4_target',
    'consideration_5',
    'consideration_6',
    'copeChange',
    'courage_1',
    'covid_13',
    'covid_3',
    'covid_4',
    'covid_7',
    'creativity',
    'customerservice_1',
    'customerservice_2',
    'customerservice_3',
    'customerservice_4',
    'dependability_1',
    'development_1',
    'development_2',
    'diversity_1',
    'diversity_2',
    'diversity_3',
    'diversity_4',
    'diversity_5',
    'emo_labor_1',
    'empowerment',
    'empowerment_1',
    'empowerment_2',
    'empowerment_3',
    'fairProcedure',
    'fairness_1',
    'fairness_2',
    'fairness_3',
    'fairness_3_target',
    'fairness_4',
    'fairness_5',
    'family',
    'feedback',
    'feedback_1',
    'feedbackseeking_1',
    'freeCovid19Nudges',
    'getManagerSupport',
    'goalsetting_1',
    'growth_1',
    'growth_2',
    'happiness_5',
    'helpYourManager',
    'highScoring',
    'humility',
    'impact_1',
    'innovation',
    'innovation_1',
    'innovation_2',
    'innovation_3',
    'insipiration_2',
    'inspiration',
    'inspiration_1',
    'inspiration_2',
    'inspiration_3',
    'inspirtation_3',
    'layoffs',
    'leadingRemote',
    'listening_1',
    'managerRelationship',
    'managerSupport',
    'manager_intro_4',
    'mastery',
    'me_execution_2',
    'me_thoughtfulness_1',
    'me_thoughtfulness_2',
    'me_thoughtfulness_3',
    'mentorship',
    'mission_1',
    'mission_2',
    'mission_3',
    'mission_4',
    'ownGrowth',
    'prioritizing_1',
    'productivity',
    'progress_1',
    'progress_2',
    'progress_3',
    'progress_4',
    'progress_5',
    'progress_6',
    'progress_7',
    'pscyhsafety_1',
    'psychsafety_1',
    'psychsafety_2',
    'recognition',
    'recognition_1',
    'recognition_2',
    'recognition_cf_3',
    'relationship_1',
    'relationships_1',
    'responsiveness',
    'responsiveness_1',
    'responsiveness_2',
    'responsiveness_3',
    'responsiveness_4',
    'scheduling_2',
    'scheduling_3',
    'social',
    'socialConnection',
    'starterNudge',
    'stress',
    'structure',
    'structure_1',
    'support_1',
    'support_2',
    'support_3',
    'teamCommunication',
    'team_intro_1',
    'team_intro_3',
    'team_intro_4',
    'teamwork',
    'transparency',
    'transparency_1',
    'transparency_2',
    'transparency_3',
    'transparency_4',
    'transparency_5',
    'vision',
    'voiceAndInfluence',
    'voice_1',
    'voice_2',
    'voice_2.voice_3.voice_4',
    'voice_2.voice_4',
    'voice_3',
    'voice_4',
    'voice_5',
    'whatDidYouLearnNudge',
    'workLife',
    'perfReview',
    'careerDev',
    'freshStarts',
];
