import bgImage from '@app/assets/images/sign.jpg';
import humulogo from '@app/assets/images/humu/logo.png';
import { useForm } from 'react-hook-form';
import { useConfirmPasswordMutation } from '@app/services/authApi';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { ConfirmPasswordErrorResponse } from '@app/services/types/LoginResponse';
import Loader from '@app/components/utility/loader';
import { useState } from 'react';

const ConfirmPassword = () => {
    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors }
    } = useForm();
    const [confirmPassword, { isLoading }] = useConfirmPasswordMutation();
    const navigate = useNavigate();

    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const token = searchParams.get('token');

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const onSubmit = async (data) => {
        setError('');
        try {
            setLoading(true);
            await confirmPassword(data).unwrap();
            setLoading(false);
            toast.success('Password successfully changed, please login', {
                duration: 5000
            });
            navigate('/login');
        } catch (err) {
            setLoading(false);
            if (err.status === 404) {
                toast.error('Token is invalid or expired', {
                    duration: 5000
                });
            }
            const error = err as ConfirmPasswordErrorResponse;
            setError(error.data.password.toString());
        }
    };
    if (isLoading) {
        return <Loader />;
    }
    return (
        <>
            {!loading ? null : <Loader />}
            <div className="container-fluid humu-sign-in">
                <div className="row">
                    <div
                        className="col-12 order-2 order-lg-1 col-lg-7 col-xl-9 sign-in-left vh-100"
                        style={{ backgroundImage: `url(${bgImage})` }}
                    ></div>
                    <div className="col-12 order-1 order-lg-2 col-lg-5 col-xl-3 sign-in-right vh-100">
                        <div className="humuLoginContent humuForgotPw">
                            <div className="row">
                                <div className="col-12 humuSignInLogo text-center">
                                    <img alt="humu" src={humulogo} />
                                </div>
                                <div className="col-12 mt-5">
                                    <h3>Change password</h3>
                                    <p>
                                        Create a new, strong password, 8
                                        characters or more. Letters, digits and
                                        @/./+/-/_ only.
                                    </p>
                                </div>
                                <div className="col-12 mt-3">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="mb-3 row">
                                            <div className="col-12">
                                                <label>New Password</label>
                                                <input
                                                    hidden={true}
                                                    value={token}
                                                    {...register('token', {
                                                        required:
                                                            'Token Required',
                                                        validate: (value) =>
                                                            value !== null
                                                    })}
                                                />
                                                <input
                                                    className="form-control"
                                                    type="password"
                                                    onChange={() => {
                                                        setError('');
                                                    }}
                                                    {...register('password', {
                                                        required:
                                                            'Password & Confirm Password Required',
                                                        validate: (value) =>
                                                            value ===
                                                                getValues(
                                                                    'confirmPassword'
                                                                ) ||
                                                            'The passwords don’t match, please try again'
                                                    })}
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-12">
                                                <label>
                                                    Confirm New Password
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="password"
                                                    onChange={() => {
                                                        setError('');
                                                    }}
                                                    {...register(
                                                        'confirmPassword',
                                                        {
                                                            required:
                                                                'Password & Confirm Password Required',
                                                            validate: (value) =>
                                                                value ===
                                                                    getValues(
                                                                        'password'
                                                                    ) ||
                                                                'The passwords don’t match, please try again'
                                                        }
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className={'mb-3 row'}>
                                            {errors.password ? (
                                                <label
                                                    className={
                                                        'md-editor-error'
                                                    }
                                                >
                                                    {errors.password.message.toString()}
                                                </label>
                                            ) : (
                                                <label
                                                    className={
                                                        'md-editor-error'
                                                    }
                                                >
                                                    {error}
                                                </label>
                                            )}
                                        </div>
                                        <div className="row g-0">
                                            <div className="col-12 text-end">
                                                <input
                                                    className="btn btn-primary btn-full"
                                                    type="submit"
                                                    value="Send Request"
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ConfirmPassword;
