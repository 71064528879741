import React from 'react';
import { Layout } from 'antd';
import {
    default as humuapplications,
    default as humurationals
} from '@app/assets/images/humu/form.png';
import humunudges from '@app/assets/images/humu/hand.png';
import humulogo from '@app/assets/images/humu/logo.png';
import TopbarWrapper from '@app/assets/styles/HumuTopbar.styles';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import { resetStateAction } from '@app/store/actions/resetState';
import { useDispatch } from 'react-redux';
import DropdownMenu, {
    IMenuItem
} from '@app/components/DropdownMenu/DropdownMenu';

const { Header } = Layout;

const TopBar = () => {
    const dispatch = useDispatch();
    const menuItems: IMenuItem[] = [
        {
            title: 'Logout',
            onPress: () => {
                dispatch(resetStateAction());
            }
        }
    ];
    return (
        <TopbarWrapper>
            <Header className={'humuTopBar'}>
                <Container fluid>
                    <Navbar expand="lg">
                        <Navbar.Brand className="navbar-brand humuTopLogo">
                            <img alt="humu" src={humulogo} />
                        </Navbar.Brand>
                        <Navbar.Toggle
                            className="humuToggler"
                            aria-controls="humuNavbar"
                        />
                        <Navbar.Collapse id="humuNavbar">
                            <Nav className="me-auto">
                                <Nav.Link
                                    as={Link}
                                    to="/nudges"
                                    className="me-3"
                                >
                                    <img
                                        src={humunudges}
                                        className="me-2"
                                        alt="Nudges"
                                    />
                                    <span>Nudges</span>
                                </Nav.Link>
                                <Nav.Link
                                    as={Link}
                                    to="/rationales"
                                    className="me-3"
                                >
                                    <img
                                        src={humurationals}
                                        className="me-2"
                                        alt="Rationales"
                                    />
                                    Rationales
                                </Nav.Link>
                                <Nav.Link
                                    as={Link}
                                    to="/applications"
                                    className="me-3"
                                >
                                    <img
                                        src={humuapplications}
                                        className="me-2"
                                        alt="Applications"
                                    />
                                    Applications
                                </Nav.Link>
                                <Nav.Link
                                    as={Link}
                                    to="/paragraphs"
                                    className="me-3"
                                >
                                    <img
                                        src={humuapplications}
                                        className="me-2"
                                        alt="Paragraphs"
                                    />
                                    Paragraphs
                                </Nav.Link>

                                <Nav.Link
                                    as={Link}
                                    to={'/email'}
                                    className="me-3"
                                >
                                    <img
                                        src={humuapplications}
                                        className="me-2"
                                        alt="Emails"
                                    />
                                    Emails
                                </Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                        <DropdownMenu items={menuItems} />
                    </Navbar>
                </Container>
            </Header>
        </TopbarWrapper>
    );
};

export default TopBar;
