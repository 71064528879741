import { IRationaleResponseModel } from '@app/entities/models/Rationale/ResponseModels/IRationaleResponseModel';
import { StringHelper } from '@app/utilities/helpers/StringHelper';
import { UserHelper } from '@app/utilities/helpers/UserHelper';
import React from 'react';
import { IApplicationResponseModel } from '@app/entities/models/Application/ResponseModels/IApplicationResponseModel';

interface IProps {
    data: IRationaleResponseModel | IApplicationResponseModel;
    type: 'Rationales' | 'Applications';
}
export const DetailsHeader = ({ data, type }: IProps) => {
    return (
        <div className="row">
            <div className="col-12">
                <div className="card rationales-top-box-table">
                    <div className="card-body">
                        <table>
                            <thead>
                                <th>Name</th>
                                <th>Content Status</th>
                                <th>Tag Status</th>
                                <th className="table-rationales">{type}</th>
                                <th>Last Updated</th>
                                <th>Last Updated By</th>
                                <th>Created By</th>
                            </thead>
                            <tbody>
                                <td>{data.name}</td>
                                <td>{data.content_status}</td>
                                <td>{data.tag_status}</td>
                                <td className="table-rationales">
                                    {StringHelper.stripTags(data.text)}
                                </td>
                                <td>
                                    {StringHelper.formatDate(data.updated_at)}
                                </td>
                                <td>
                                    {UserHelper.userFullName(data.modified_by)}
                                </td>
                                <td>
                                    {UserHelper.userFullName(data.created_by)}
                                </td>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};
