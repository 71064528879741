import { Tooltip, Typography } from '@mui/material';

export const TooltipView = (value: string) => {
    return (
        <Tooltip title={value}>
            <Typography
                noWrap
                fontSize={14}
                component={'span'}
                variant={'body1'}
            >
                {value}
            </Typography>
        </Tooltip>
    );
};
