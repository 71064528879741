import bgImage from '@app/assets/images/sign.jpg';
import humulogo from '@app/assets/images/humu/logo.png';
import { useForm } from 'react-hook-form';
import { useLoginMutation } from '@app/services/authApi';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import Loader from '../../components/utility/loader';

const Login = () => {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();
    const [login, { isLoading }] = useLoginMutation();
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        try {
            await login(data).unwrap();
            toast.success('Successfully logged in', {
                duration: 5000
            });
            navigate('/');
        } catch (err) {
            toast.error('Invalid Username or Password');
        }
    };
    if (isLoading) {
        return <Loader />;
    }
    return (
        <>
            <div className="container-fluid humu-sign-in">
                <div className="row">
                    <div
                        className="col-12 order-2 order-lg-1 col-lg-7 col-xl-9 sign-in-left vh-100"
                        style={{ backgroundImage: `url(${bgImage})` }}
                    ></div>
                    <div className="col-12 order-1 order-lg-2 col-lg-5 col-xl-3 sign-in-right vh-100">
                        <div className="humuLoginContent">
                            <div className="row">
                                <div className="col-12 humuSignInLogo text-center">
                                    <img alt="humu" src={humulogo} />
                                </div>
                                <div className="col-12 mt-5">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="mb-3 row">
                                            <div className="col-12">
                                                <input
                                                    className="form-control"
                                                    placeholder="name@example.com"
                                                    type="email"
                                                    {...register('username')}
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-12">
                                                <input
                                                    className="form-control"
                                                    placeholder="password"
                                                    type="password"
                                                    {...register('password', {
                                                        required: true
                                                    })}
                                                />
                                                {errors.password && (
                                                    <span>
                                                        This field is required
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row g-0">
                                            <div className="col-6 align-middle">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value=""
                                                        id="flexCheckDefault"
                                                    />
                                                    <label className="form-check-label">
                                                        Remember me
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-6 text-end">
                                                <input
                                                    className="btn btn-primary"
                                                    type="submit"
                                                    value="Sign In"
                                                />
                                            </div>
                                        </div>
                                    </form>
                                    <div className="humuLoginButtonBox mt-5 pt-5">
                                        <div className="row">
                                            <div className="col-12 text-center humuForgotPW">
                                                <p className="mb-3">
                                                    <Link to="/forgot-password">
                                                        Forgot Password
                                                    </Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
