import { Stack } from '@mui/material';

export const NoRowsOverlay = () => {
    return (
        <Stack height="100%" alignItems="center" justifyContent="center">
            No record found, matching your criteria
        </Stack>
    );
};

export const NoResultsOverlay = () => {
    return (
        <Stack height="100%" alignItems="center" justifyContent="center">
            No record found, matching your criteria
        </Stack>
    );
};
