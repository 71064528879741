import avatar from '@app/assets/images/avatar.png';
import React from 'react';

const Avatar = () => {
    return (
        <div className="isoUser">
            <div className="isoImgWrapper ant-popover-open">
                <img alt="user" src={avatar} />
                <span className="userActivity online"></span>
            </div>
        </div>
    );
};

export default Avatar;
