import { emptySplitApi } from './emptySplitApi';
import { IRationalesResponseModel } from '@app/entities/models/Rationale/ResponseModels/IRationalesResponseModel';
import { IRationaleResponseModel } from '@app/entities/models/Rationale/ResponseModels/IRationaleResponseModel';
import { IRationalePayloadModel } from '@app/entities/models/Rationale/PayloadModels/IRationalePayloadModel';
import { ApiParams, ParagraphApiParam } from './types/apiParams';
import { constructGetListParams } from './ApiHelper';

export const rationalesApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getEmails: builder.query({
            query: (params: ApiParams) => {
                return {
                    url: `api/emailContacts/?${constructGetListParams(params)}`
                };
            },
            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result.results?.map(({ id }) => ({
                              type: 'emails' as const,
                              id
                          })),
                          'emails'
                      ]
                    : []
        }),
        getParagraphs: builder.query<
            IRationalesResponseModel,
            ParagraphApiParam
        >({
            query: (params: ParagraphApiParam) => {
                return {
                    url: `api/paragraphBoxes/?purpose=${
                        params.purpose
                    }&${constructGetListParams(params)}`
                };
            },
            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result.results?.map(({ id }) => ({
                              type: 'Nudges' as const,
                              id
                          })),
                          'Nudges'
                      ]
                    : []
        }),
        getRationales: builder.query<IRationalesResponseModel, ApiParams>({
            query: (params: ApiParams) => {
                return {
                    url: `api/rationales/?${constructGetListParams(params)}`
                };
            },
            providesTags: (result, error, arg) =>
                result
                    ? [
                          ...result.results?.map(({ id }) => ({
                              type: 'Rationales' as const,
                              id
                          })),
                          'Rationales'
                      ]
                    : ['Rationales']
        }),
        getRationaleById: builder.query<IRationaleResponseModel, number>({
            query: (id: number) => ({
                url: `api/rationales/${id}/`
            }),
            providesTags: (result, error, id) => [{ type: 'Rationales', id }]
        }),
        addRationale: builder.mutation<
            IRationaleResponseModel,
            IRationalePayloadModel
        >({
            query: (rationale) => ({
                url: 'api/rationales/',
                method: 'POST',
                body: rationale
            }),
            invalidatesTags: ['Rationales']
        }),
        updateRationale: builder.mutation<
            IRationaleResponseModel,
            IRationalePayloadModel
        >({
            query: (rationale) => ({
                url: `api/rationales/${rationale.id}/`,
                method: 'PATCH',
                body: rationale
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Rationales', id: arg.id }
            ]
        })
    })
});

export const {
    useGetRationalesQuery,
    useGetEmailsQuery,
    useGetParagraphsQuery,
    useUpdateRationaleMutation,
    useGetRationaleByIdQuery,
} = rationalesApi;
