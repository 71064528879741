import { GridFooter, GridFooterContainer } from '@mui/x-data-grid';
import '../CustomFooter/style.css'
interface CustomFooterProps  { totalRecords: number };

export function CustomFooter(props: CustomFooterProps) {
    return (
        <GridFooterContainer>
            <label className='footer-text'>Total Records: {props.totalRecords}</label>
            <GridFooter
                sx={{
                    border: 'none'
                }}
            />
        </GridFooterContainer>
    );
}
