import bgImage from '@app/assets/images/sign.jpg';
import humulogo from '@app/assets/images/humu/logo.png';
import { useForm } from 'react-hook-form';
import { useResetPasswordMutation } from '@app/services/authApi';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { ResetPasswordErrorResponse } from '@app/services/types/LoginResponse';
import Loader from '@app/components/utility/loader';
import { useState } from 'react';

const ForgotPassword = () => {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();
    const [resetPassword, { isLoading }] = useResetPasswordMutation();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        try {
            setLoading(true);
            await resetPassword(data).unwrap();
            setLoading(false);
            toast.success('Reset link sent to your email.', {
                duration: 5000
            });
            navigate('/login');
        } catch (err) {
            setLoading(false);
            const error = err as ResetPasswordErrorResponse;
            toast.error(error.data.email.toString().replaceAll(',', '\n'));
        }
    };
    if (isLoading) {
        return <Loader />;
    }
    return (
        <>
            {!loading ? null : <Loader />}
            <div className="container-fluid humu-sign-in">
                <div className="row">
                    <div
                        className="col-12 order-2 order-lg-1 col-lg-7 col-xl-9 sign-in-left vh-100"
                        style={{ backgroundImage: `url(${bgImage})` }}
                    ></div>
                    <div className="col-12 order-1 order-lg-2 col-lg-5 col-xl-3 sign-in-right vh-100">
                        <div className="humuLoginContent humuForgotPw">
                            <div className="row">
                                <div className="col-12 humuSignInLogo text-center">
                                    <img alt="humu" src={humulogo} />
                                </div>
                                <div className="col-12 mt-5">
                                    <h3>Forgot Password?</h3>
                                    <p>
                                        Enter your email and we send you a reset
                                        link.
                                    </p>
                                </div>
                                <div className="col-12 mt-3">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="mb-3 row">
                                            <div className="col-12">
                                                <input
                                                    className="form-control"
                                                    placeholder="name@example.com"
                                                    type="email"
                                                    {...register('email', {
                                                        required: 'Required'
                                                    })}
                                                />
                                                {errors.email && (
                                                    <label
                                                        className={
                                                            'md-editor-error'
                                                        }
                                                    >
                                                        {errors.email.message.toString()}
                                                    </label>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row g-0">
                                            <div className="col-12 text-end">
                                                <input
                                                    className="btn btn-primary btn-full"
                                                    type="submit"
                                                    value="Send Request"
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ForgotPassword;
