import React from 'react';

let direction: any = 'ltr';
if (typeof window !== 'undefined') {
    direction = document.getElementsByTagName('html')[0].getAttribute('dir');
}
// const withDirection = props: any) => {
//   return <Component {...props} data-rtl={direction} />;
// };

const withDirection = (Comp: any) => (props: any) => {
    return <Comp {...props} />;
};

export default withDirection;
export { direction };
