import { RootState } from '@app/store/store';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_ApiBaseUrl,
    prepareHeaders: (headers, { getState, endpoint }) => {
        const auth = (getState() as RootState).auth;

        if (auth.access && endpoint !== 'refresh') {
            headers.set('Authorization', `Bearer ${auth.access}`);
        }

        return headers;
    },
    // credentials: 'include', // This allows server to set cookies
});

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptySplitApi = createApi({
    reducerPath: 'api',
    baseQuery,
    tagTypes: ['Nudges', 'Rationales', 'Applications'],
    endpoints: () => ({}),
});
