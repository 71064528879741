import { createSlice } from '@reduxjs/toolkit'
import { authApi } from '@app/services/authApi';

export interface AuthState {
    access?: string;
    refresh?: string;
}

const initialState: AuthState = {
    access: null,
    refresh: null,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addMatcher(
                authApi.endpoints.login.matchFulfilled,
                (state, { payload }) => {
                    state.access = payload.access;
                    state.refresh = payload.refresh;
                }
            )
        },
});

export default authSlice.reducer;
