import bulletGreen from '@app/assets/images/icon/bulletGreen.svg';
import bulletYellow from '@app/assets/images/icon/bulletYellow.svg';
import { FormControlLabel, IconButton } from '@mui/material';

export function ContentStatusIcon(value: string) {
    const valueIconMap = {
        draft: bulletYellow,
        pushed: bulletGreen
    };
    const iconSrc = valueIconMap[value] ?? '';
    return (
        <FormControlLabel
            control={
                <IconButton>
                    <img src={iconSrc} alt="" />
                </IconButton>
            }
            label={<span style={{ fontSize: '14.5px' }}>{value}</span>}
        />
    );
}
